import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import {EventBus} from "@/helper/EventBus";
import vueNumeralFilterInstaller from 'vue-numeral-filter';
import "./plugins/vuetify-money.js";
import primeVue from './plugins/prime-vue'
import VueNumberFormat from '@coders-tm/vue-number-format'
import { ValidationObserver,ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules'

// Extend the validation rules
extend('required', {
  ...required,
  message: 'This field is required'
})



// import './plugins/number-formatter'
import './app.css'
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.use(VueNumberFormat, { precision: 4 })
Vue.use(primeVue);
Vue.use(vueNumeralFilterInstaller);
Vue.config.productionTip = false
Vue.use(require('vue-moment'));
Vue.prototype.$eventBus = EventBus;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
