<template>
    <div id="chart">
        <apexchart type="line" height="450" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
    import VueApexCharts from "vue-apexcharts";

    export default {
        name: "lineChart",
        components: {
            apexchart: VueApexCharts
        },
        props: {
            series: {
                type: Array,
                required: true,
                default: function () {
                    return [{
                        name: "Total Revenue",
                        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                    },
                        {
                            name: "Target",
                            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                        },
                    ]
                }
            }
        },
        data: () => ({



            chartOptions: {
                chart: {
                    height: 450,
                    type: 'line',
                    zoom: {
                        enabled: false
                    }
                },
                colors: ['#fc5056','#116ac2','#15b11d',  '#546E7A',  '#FF9800'],
                dataLabels: {
                    enabled: true,
                    textAnchor: 'top',
                    background: {
                        enabled: true,
                    },
                    formatter: function (value) {
                        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }
                },
                stroke: {
                    curve: 'smooth',
                    lineCap: 'butt',
                    width: 2,
                    dashArray: 0,
                },
                title: {
                    text: 'Revenue',
                    align: 'left'
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep','Oct','Nov','Dec'],
                },
                yaxis: {
                    labels: {
                        formatter: function (value) {
                            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        }
                    }
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        }
                    }
                }
            },


        }),

    }
</script>

<style scoped>

</style>