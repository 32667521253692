<template>
    <v-app >

        <v-app-bar dark color="#172C6B" elevation="1"  app  >
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

            <v-toolbar-title >LA GROUP DASHBOARD</v-toolbar-title>

            <v-spacer></v-spacer>
            <v-btn text plain :ripple="false" >
                <v-icon>mdi-account</v-icon> {{$store.getters.getUsername}}
            </v-btn>
            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            icon
                    >
                        <v-icon >mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>
                <v-list dense width="120">
                    <v-list-item @click="logout">
                        <v-list-item-icon class="mr-1"><v-icon>mdi-logout</v-icon></v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title @click="logout">Logout</v-list-item-title>
                        </v-list-item-content>

                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>
        <v-navigation-drawer  dark color="#172C6B" permanent app :mini-variant="drawer" width="200">
            <v-list>
                <v-list-item class="px-2 " >
                    <v-img sizes="50" contain eager :src="require('@/assets/LAG-LOGO-2.png')"></v-img>
                </v-list-item>

            </v-list>

            <v-divider></v-divider>

            <v-list
                    dense
                    nav
                    shaped
            >
                <v-list-item-title>Report</v-list-item-title>
                <v-list-item
                        v-for="item in items"
                        :key="item.title"
                        :to="item.to"

                >
                    <v-list-item-icon class="mr-3">
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-list
                    dense
                    nav
                    shaped
            >
                <v-list-item-title>Setting</v-list-item-title>
                <v-list-item
                        v-for="item in setting"
                        :key="item.title"
                        :to="item.to"

                >
                    <v-list-item-icon class="mr-3">
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

        </v-navigation-drawer>
        <loader-component></loader-component>
        <action-status></action-status>
        <v-main class="mx-2 mt-2" >
            <router-view/>
        </v-main>
        <v-footer padless>
            <v-col
                    class="text-center"
                    cols="12"
            >
                LA GROUP CO.,LTD  {{ new Date().getFullYear() }} — <strong> <v-icon>mdi-copyright</v-icon> Reserved</strong>
            </v-col>
        </v-footer>
    </v-app>
</template>

<script>
    import LoaderComponent from "@/components/Loader";
    import ActionStatus from "@/components/actionStatus";
    import {mapGetters} from "vuex";
    export default {
        name: "adminLayout",
        components: {ActionStatus, LoaderComponent},
        data () {
            return {
                items: [
                    { title: 'Dashboard', icon: 'mdi-view-dashboard',to:'/admin/dashboard'  },
                    { title: 'Individual Revenue', icon: 'mdi-home-search-outline',to:'/admin/individual'  },
                    // { title: 'Target Report', icon: 'mdi-bullseye-arrow',to:'/admin/employee'  },
                    { title: 'Daily Report', icon: 'mdi-finance',to:'/admin/report'  },
                ],
                setting:[

                    { title: 'Manage Company', icon: 'mdi-domain',to:'/admin/company'  },
                    // { title: 'Set Target', icon: 'mdi-target-variant',to:'/admin/department'  },
                    { title: 'Manage User', icon: 'mdi-account',to:'/admin/user'  },
                ]

            }
        },
        computed:{
            ...mapGetters(['isDrawerOpen']),
            drawer:{
                get() {
                    return this.isDrawerOpen;
                },
                // eslint-disable-next-line no-unused-vars
                set(newValue) {
                    this.$store.commit('toggleDrawer');
                },
            }
        },
        methods:{
           async logout(){
               await this.$store.dispatch('logout')
            }
        }
    }
</script>

<style scoped>
    .la-color-dark{
        background-color: #172C6B;
    }
    .bottom-button {
        position: absolute;
        bottom: 0px; /* Adjust for spacing */
        /*left: 50%;*/
        /*transform: translateX(-50%);*/
    }
</style>
