import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import userLayout from "@/views/userLayout";
import adminLayout from "@/views/adminLayout";
import store from "@/store"
import notfoundComponent from "@/views/notfoundComponent";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: 'login'
    },
    {
        path: '/login',
        name: 'login',
        component: () => import( '@/views/LoginView')
    },

    //region user
    {
        path: '/access-denied',
        name: 'access-denied',
        meta: {layout: userLayout, auth: true, role: 'user'},
        component: () => import( '@/views/accessDenied')
    },
    {
        path: '/home',
        name: 'user-home',
        meta: {layout: userLayout, auth: true, role: 'user'},
        component: HomeView
    },
    {
        path: '/revenue/create',
        name: 'revenue-submit',
        meta: {layout: userLayout, auth: true, role: 'user'},
        component: () => import('@/views/users/revenueView/revenueCreate')
    },
    {
        path: '/revenue/upload',
        name: 'revenue-upload',
        meta: {layout: userLayout, auth: true, role: 'user'},
        component: () => import('@/views/users/revenueView/revenueUpload')
    },
    {
        path: '/search',
        name: 'revenue-search',
        meta: {layout: userLayout, auth: true, role: 'user'},
        component: () => import('@/views/users/revenueView/searchView')
    },
    {
        path: '/search/view/:id',
        name: 'search-detail',
        meta: {layout: userLayout, auth: true, role: 'user'},
        component: () => import( '@/views/users/revenueView/searchDetail')
    },
    {
        path: '/profile',
        name: 'profile',
        meta: {layout: userLayout, auth: true, role: 'user'},
        component: () => import('@/views/users/profileView')
    },
    //endregion user

    //region admin
    {
        path: '/admin/dashboard',
        name: 'admin-dashboard',
        meta: {layout: adminLayout, auth: true, role: 'admin'},
        component: () => import( '@/views/admin/dashBoard')
    },
    {
        path: '/admin/individual',
        name: 'admin-individual',
        meta: {layout: adminLayout, auth: true, role: 'admin'},
        component: () => import( '@/views/admin/individual')
    },
    {
        path: '/admin/report',
        name: 'admin-report',
        meta: {layout: adminLayout, auth: true, role: 'admin'},
        component: () => import( '@/views/admin/report/report')
    },
    {
        path: '/admin/report/view/:id',
        name: 'report-detail',
        meta: {layout: adminLayout, auth: true, role: 'admin'},
        component: () => import( '@/views/admin/report/reportDetail')
    },
    {
        path: '/admin/company',
        name: 'manage-company',
        meta: {layout: adminLayout, auth: true, role: 'admin'},
        component: () => import( '@/views/admin/company/companyView')
    },
    {
        path: '/admin/company/view/:id',
        name: 'company-detail',
        meta: {layout: adminLayout, auth: true, role: 'admin'},
        component: () => import( '@/views/admin/company/companyDetail')
    },
    {
        path: '/admin/company/target/:id',
        name: 'company-target',
        meta: {layout: adminLayout, auth: true, role: 'admin'},
        component: () => import( '@/views/admin/company/targetView')
    },
    {
        path: '/admin/user',
        name: 'manage-user',
        meta: {layout: adminLayout, auth: true, role: 'admin'},
        component: () => import( '@/views/admin/user/userView')
    },
    {
        path: '/admin/user/create',
        name: 'create-user',
        meta: {layout: adminLayout, auth: true, role: 'admin'},
        component: () => import( '@/views/admin/user/userCreate')
    },
    {
        path: '/admin/user/update/:id',
        name: 'update-user',
        meta: {layout: adminLayout, auth: true, role: 'admin'},
        component: () => import( '@/views/admin/user/userUpdate')
    },
    //endregion admin

    {
        path: '*',
        name: 'not-found',
        component: notfoundComponent
    }


]


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.auth)) {
        if (store.getters.isLoggedIn) {
            if (store.getters.userRole === to.meta.role) {
                next()
                return
            }

            next('/access-denied')
            return;

        }
        next('/login')
    } else {
        next()
    }
})


export default router
