import Vue from "vue";
import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import InputNumber from 'primevue/inputnumber';
Vue.component('InputNumber', InputNumber);

Vue.use(PrimeVue);
export default PrimeVue;
